import { clsx } from "clsx";

import styles from "./Panel.module.scss";

enum Size {
    default = "default",
    quarter = "quarter",
    half = "half",
    full = "full",
    px400 = "px-400",
}
// this component is only used in 3 places.
// It seems to be some sort of convience wrapper to keep divs
// consistent in size and style but I think it is way out lived its usefulness
// we would wither need to div head first into this, or abandon it
function Panel({
    children,
    className,
    size = Size.default,
    shaded = false,
    snapLeft = false,
    noPadding = false,
    justifyContentUnset = false,
    ...props
}: {
    children: any;
    className?: string;
    size?: Size;
    shaded?: boolean;
    snapLeft?: boolean;
    noPadding?: boolean;
    justifyContentUnset?: boolean;
    [x: string]: any;
}): JSX.Element {
    return (
        <div
            className={clsx(styles[size], className, {
                [styles.shaded]: shaded,
                [styles.snapLeft]: snapLeft,
                [styles.noPadding]: noPadding,
                [styles.justifyContentUnset]: justifyContentUnset,
            })}
            {...props}
        >
            {children}
        </div>
    );
}

export default Object.assign(Panel, { Size });
